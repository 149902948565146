a h4 {
  color: #2a2f33;
}
div.App {
  display: grid;
  place-items: center;
  height: 100vh;
}
main {
  display: flex;
  flex-wrap: wrap;
  column-gap: 128px;
  row-gap: 1em;
  justify-content: center;
}
main div {
  background-color: white;
  border-radius: 1em;
  padding: 1em;
  text-align: center;
}
